import { createSlice } from "@reduxjs/toolkit";

export const ConnectionState = {
  CLOSED: "closed",
  CONFIGURED: "configured",
  CONNECTED: "connected",
  FAILED: "failed",
  INITIALIZED: "initialized",
  RECONNECTING: "reconnecting",
  UNINITIALIZED: "uninitialized", // initial state
};

export const roomSlice = createSlice({
  name: "room",
  initialState: {
    peers: {},
  },
  reducers: {
    setPeers: (state, action) => {
      console.log("setPeers", action.payload);
      state.peers = action.payload;
    },
    addPeer: (state, action) => {
      let updatedPeers = {
        ...state.peers,
        [action.payload.data.peerId]: {
          ...state.peers[action.payload.data.peerId],
          [action.payload.kind]: action.payload.data,
        },
      };
      console.log("updated Peers in roomSlice are:", updatedPeers);
      state.peers = updatedPeers;
    },
  },
});

export const { setPeers, addPeer } = roomSlice.actions;

export default roomSlice.reducer;
