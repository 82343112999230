import React, { useEffect, useRef } from "react";
import { useState } from "react";
// import {useEffect} from 'react';

import PeerVideo from "../peerVideo/PeerVideo";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RoomContext, { withRoomContext } from "../../lib/RoomContext";
import { RoomClient } from "../../lib/RoomClient";
import axios from "axios";
import Tooltip from "../tooltip/tooltip";
import InfoIcon from "../../assets/image2.png";

// const meetingRoomId = "broadcaster";
// const meetingRoomId = "1234567";pe
// const urlParams = new URLSearchParams(window.location.search);
// console.log("search params", urlParams.get("roomId"));

// const res = await fetchToken();
// const roomClient = new RoomClient({
//   // sessionToken:res.token,
//   roomId: urlParams.get("roomId"),
//   peerName: urlParams.get("peerName"),
//   videoResolution: urlParams.get("videoResolution") || "qvga",
//   produce: urlParams.get("produce") || true,
//   produceAudio: urlParams.get("produceAudio") || true,
//   produceVideo: urlParams.get("produceVideo") || true,
//   forcePCMU: urlParams.get("forcePCMU") || true,
//   forceH264: urlParams.get("forceH264") || true,
//   h264Profile: urlParams.get("h264Profile") || "low",
//   forceFPS: urlParams.get("forceFPS") || 10,
//   enableWebcamLayers: urlParams.get("enableWebcamLayers") || true,
//   numSimulcastStreams: urlParams.get("numSimulcastStreams") || 1,
//   videoBitRates: [
//     urlParams.get("videoBitRateHigh") || 300,
//     urlParams.get("videoBitRateMedium") || 150,
//     urlParams.get("videoBitRateLow") || 75,
//   ],
//   autoGainControl: urlParams.get("autoGainControl") || true,
//   echoCancellation: urlParams.get("echoCancellation") || true,
//   noiseSuppression: urlParams.get("noiseSuppression") || true,
//   sampleRate: urlParams.get("sampleRate") || 8000,
//   channelCount: urlParams.get("channelCount") || 1,
// });

const MeetingRoom = () => {
  // const [peers, setPeers] = useState({});
  const navigate = useNavigate();
  const [sessionToken, setSessionToken] = useState(null);
  const peers = useSelector((state) => state.room.peers);
  const [slicedIndex, setSlicedIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const roomId = urlParams.get("roomId");
  const [isTooltipVisible, setIsToolTipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsToolTipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsToolTipVisible(false);
  };
  console.log("peers from meeting room", peers);
  const inputParams = {
    roomId: urlParams.get("roomId"),
    peerName: urlParams.get("peerName"),
    videoResolution: urlParams.get("videoResolution") || "hd",
    produce:
      urlParams.get("produce") === null || urlParams.get("produce") === "true"
        ? true
        : false,
    produceAudio:
      urlParams.get("produceAudio") === null ||
      urlParams.get("produceAudio") === "true"
        ? true
        : false,
    produceVideo:
      urlParams.get("produceVideo") === null ||
      urlParams.get("produceVideo") === "true"
        ? true
        : false,
    forcePCMU: urlParams.get("forcePCMU") === "true" ? true : false,
    forceH264: urlParams.get("forceH264") === "true" ? true : false,
    h264Profile: urlParams.get("h264Profile") === "low" ? "low" : "high",
    forceFPS:
      !isNaN(Number(urlParams.get("forceFPS"))) &&
      Number(urlParams.get("forceFPS")) > 0 &&
      Number(urlParams.get("forceFPS")) <= 60
        ? Number(urlParams.get("forceFPS"))
        : 30,
    enableWebcamLayers:
      urlParams.get("enableWebcamLayers") === null ||
      urlParams.get("enableWebcamLayers") === "true"
        ? true
        : false,
    numSimulcastStreams:
      !isNaN(Number(urlParams.get("numSimulcastStreams"))) &&
      Number(urlParams.get("numSimulcastStreams")) > 0 &&
      Number(urlParams.get("numSimulcastStreams")) <= 3
        ? Number(urlParams.get("numSimulcastStreams"))
        : 3,
    videoBitRates: [
      !isNaN(Number(urlParams.get("videoBitRateHigh"))) &&
      Number(urlParams.get("videoBitRateHigh")) > 50 &&
      Number(urlParams.get("videoBitRateHigh")) <= 1000
        ? Number(urlParams.get("videoBitRateHigh"))
        : 500,
      !isNaN(Number(urlParams.get("videoBitRateMedium"))) &&
      Number(urlParams.get("videoBitRateMedium")) > 30 &&
      Number(urlParams.get("videoBitRateMedium")) <= 300
        ? Number(urlParams.get("videoBitRateMedium"))
        : 250,
      !isNaN(Number(urlParams.get("videoBitRateLow"))) &&
      Number(urlParams.get("videoBitRateLow")) > 10 &&
      Number(urlParams.get("videoBitRateLow")) <= 125
        ? Number(urlParams.get("videoBitRateLow"))
        : 100,
    ],
    autoGainControl:
      urlParams.get("autoGainControl") === null ||
      urlParams.get("autoGainControl") === "true"
        ? true
        : false,
    echoCancellation:
      urlParams.get("echoCancellation") === null ||
      urlParams.get("echoCancellation") === "true"
        ? true
        : false,
    noiseSuppression:
      urlParams.get("noiseSuppression") === null ||
      urlParams.get("noiseSuppression") === "true"
        ? true
        : false,
    sampleRate:
      !isNaN(Number(urlParams.get("sampleRate"))) &&
      Number(urlParams.get("sampleRate")) >= 8000 &&
      Number(urlParams.get("sampleRate")) <= 64000
        ? Number(urlParams.get("sampleRate"))
        : 44000,
    channelCount:
      !isNaN(Number(urlParams.get("channelCount"))) &&
      Number(urlParams.get("channelCount")) >= 1 &&
      Number(urlParams.get("channelCount")) <= 8
        ? Number(urlParams.get("channelCount"))
        : 1,
    msRegion: "us", //APAC,EU,US,
    backgroundImage: "", //Imagelink
  };
  console.log("input params", inputParams);
  // const roomId = "12387638";
  if (!roomId) {
    window.alert("Please enter your room Id");
  }
  const handleNextPeerClick = (slicedIndex) => {
    setSlicedIndex((prevIndex) => prevIndex + 6);
  };
  const handlePrevPeerClick = (slicedIndex) => {
    setSlicedIndex((prevIndex) => prevIndex - 6);
  };

  const handleRoomClose = () => {
    console.log("Peer while leaving the room", peers);
  };

  const fetchSessionToken = async () => {
    if (sessionToken) {
      return;
    }
    setLoading(true);
    console.log("Room Id is", roomId);
    try {
      const response = await axios.post("/api/create-session-token", {
        roomId,
      });
      if (
        response.data.message === "Session token fetched successfully" ||
        response.data.message === "Active session token fetched successfully"
      ) {
        console.log(response);
        setSessionToken(response.data.sessionToken);
      }
    } catch (error) {
      console.error("Error fetching session token:", error);
      // window.alert("Error fetching session token. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSessionToken();
  }, [roomId]);

  const roomClientRef = useRef(null);

  useEffect(() => {
    if (sessionToken) {
      try {
        let urlParams = {
          sessionToken,
          ...inputParams,
        };
        console.log("input params with session token", urlParams);
        roomClientRef.current = new RoomClient(urlParams);
      } catch (error) {
        console.error("Error initializing RoomClient:", error);
      }
    }
  }, [sessionToken]);
  return (
    <RoomContext.Provider value={roomClientRef.current}>
      <div className="App">
        {loading ? (
          <div className="loading-indicator">Loading...</div>
        ) : (
          <div className="app-container">
            <div
              className="info-tool-tip"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img className="info-icon" src={InfoIcon} alt=""></img>
              {isTooltipVisible && <Tooltip params={inputParams} />}
            </div>

            {Object.values(peers)
              .slice(slicedIndex, slicedIndex + 6)
              .map((peer, index) => (
                <div className="peer-container" key={index}>
                  <PeerVideo peerId={peer.peerId} />
                </div>
              ))}
            <button
              style={{ zIndex: "9999" }}
              onClick={() => {
                roomClientRef.current?.videoedgeClient?.leaveRoom();
                handleRoomClose();
                navigate("/thanks");
              }}
            >
              Leave Room
            </button>
          </div>
        )}
        {!loading && (
          <div className="next-prev-buttons">
            {slicedIndex + 6 < peers.length ? (
              <button onClick={handleNextPeerClick}>NEXT</button>
            ) : null}

            {slicedIndex > 0 ? (
              <button onClick={handlePrevPeerClick}>PREV</button>
            ) : null}
          </div>
        )}
      </div>
    </RoomContext.Provider>
  );
};

export default MeetingRoom;
