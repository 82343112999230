// import VidScale from "./VidScale";
import { setPeers } from "../store/slices/roomSlice";
import store from "../store/store";
import videoedge from "videoedge-js";

export class RoomClient {
  constructor(params) {
    this.videoedgeClient = null;
    this.init(params);
  }

  async init(params) {
    console.log("VidScale client", videoedge);
    if (!params.roomId) {
      alert("Room id is required!");
      return;
    } else {
      const vidScale = await videoedge.JsSdk.joinRoom(params);
      vidScale.on("newPeer", ({ peerId, peerName, type }) => {
        console.log("Room joined", store.getState().room.peers);
        let peers = store.getState().room.peers;
        let updatedPeers = {
          ...peers,
          [peerId]: { peerName, peerId, me: type === "local" ? true : false },
        };
        //   setPeers(updatedPeers);
        store.dispatch(setPeers(updatedPeers));
      });
      vidScale.on("videoStart", ({ peerId, videoTrack, type }) => {
        console.log("video started");
        let peers = store.getState().room.peers;
        let peer = peers[peerId];
        if (peer) {
          console.log("peer video started", peerId);
          let updatedPeers = {
            ...peers,
            [peerId]: { ...peers[peerId], video: true, videoTrack },
          };
          store.dispatch(setPeers(updatedPeers));
        }
      });
      vidScale.on("videoEnd", ({ peerId, videoTrack, type }) => {
        console.log("video end");
        let peers = store.getState().room.peers;
        let peer = peers[peerId];
        if (peer) {
          console.log("peer video ended", peerId);
          let updatedPeers = {
            ...peers,
            [peerId]: { ...peers[peerId], video: false, videoTrack },
          };
          store.dispatch(setPeers(updatedPeers));
        }
      });
      vidScale.on("micStart", ({ peerId, audioTrack, type }) => {
        console.log("mic started");
        let peers = store.getState().room.peers;
        let peer = peers[peerId];
        if (peer) {
          console.log("peer mic started", peerId);
          let updatedPeers = {
            ...peers,
            [peerId]: { ...peers[peerId], mic: true, audioTrack },
          };
          store.dispatch(setPeers(updatedPeers));
        }
      });

      vidScale.on("micEnd", ({ peerId, audioTrack, type }) => {
        console.log("mic ended");
        let peers = store.getState().room.peers;
        let peer = peers[peerId];
        if (peer) {
          console.log("peer mic ended", peerId);
          let updatedPeers = {
            ...peers,
            [peerId]: { ...peers[peerId], mic: false, audioTrack },
          };
          store.dispatch(setPeers(updatedPeers));
        }
      });

      vidScale.on("peerMuted", ({ peerId, type }) => {
        console.log("Peer muted");
        let peers = store.getState().room.peers;
        let peer = peers[peerId];
        if (peer) {
          console.log("peer muted", peerId);
          let updatedPeers = {
            ...peers,
            [peerId]: { ...peers[peerId], muted: true },
          };
          store.dispatch(setPeers(updatedPeers));
        }
      });

      vidScale.on("peerUnMuted", ({ peerId, type }) => {
        console.log("Peer Unmuted");
        let peers = store.getState().room.peers;
        let peer = peers[peerId];
        if (peer) {
          console.log("peer Unmuted", peerId);
          let updatedPeers = {
            ...peers,
            [peerId]: { ...peers[peerId], muted: false },
          };
          store.dispatch(setPeers(updatedPeers));
        }
      });

      vidScale.on("peerLeft", ({ peerId }) => {
        console.log("Peer Left is");
        let peers = store.getState().room.peers;
        let peer = peers[peerId];
        if (peer) {
          console.log("Remove peer for room leave", peerId);
          let updatedPeers = {
            ...peers,
          };
          delete updatedPeers[peerId];
          store.dispatch(setPeers(updatedPeers));
        }
      });

      vidScale.on("error", ({ code, text }) => {
        console.log(
          "There is an error with code: " + code + " with text: " + text
        );
      });

      this.videoedgeClient = vidScale;
    }
  }

  async mute() {
    await this.videoedgeClient.muteMic();
  }

  async unMute() {
    await this.videoedgeClient.unmuteMic();
  }

  async cameraOn() {
    await this.videoedgeClient.enableCam();
  }

  async cameraOff() {
    await this.videoedgeClient.disableCam();
  }
}
