import { configureStore } from "@reduxjs/toolkit";

import roomReducer from "./slices/roomSlice";

export default configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          "room/setPeers",
          "room/addPeer",
          "room/setShareData",
          "room/addShareDataProducer",
        ],
        // Ignore these paths in the state
        ignoredPaths: ["room.peers", "room.shareData"],
      },
    }),
  reducer: {
    room: roomReducer,
  },
});
