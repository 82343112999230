import React from "react";
import "./helpPage.modules.css";

const HelpPage = () => {
  return (
    <section className="help">
      <h1 className="help-heading">Help Section</h1>
      <section className="content-section">
        <h2 className="get-started">Get started with trying our demo</h2>
        <p className="para-1">
          To make it easy for you to check the vidscale sdk with all the
          available features and options in the sdk. Below are all the available
          configuration option which one can use while a joining a user to the
          meeting.
        </p>
        <p className="para-2">
          ONLY the roomId parameter is required, without which the room won't
          start. All other options are optional and they have a default value if
          we don't provide any value for them.
        </p>
        <div className="point-div">
          <h3>1. roomId</h3>
          <p className="para-1">
            This is the most important parameter and it is required for the user
            / peer to join the room. Without this parameter the sdk won't do
            anything. In order to pass a roomid, you need to make it available
            in the url as mentioned below.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123></abc123>`}
          </p>
          
          <p className="para-1">
            You can use any string / number combination as your roomid. But make
            make sure that it is unique as the server would let the users join a
            specific room based on this parameter.
          </p>
        </div>
        <div className="point-div">
          <h3>2. peerName</h3>
          <p className="para-1">
            This parameter is important if you wish all other is the meeting can
            indentify a user/peer in the room by his/her name. Without this
            parameter the sdk would generate a random name which may not be
            meaningful. In order to pass a peerName, you need to make it
            available in the url as mentioned below.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&peerName=<Ram>`}
          </p>
          
          <p className="para-1">
            You can use any string / number combination as your peerName.
          </p>
        </div>
        <div className="point-div">
          <h3>3. videoResolution</h3>
          <p className="para-1">
            This parameter is important if you wish to set the your camera
            resolution to a specific quality. The available values are
            "qvga"(320X240), "vga"(640X480) and "hd"(1280X720). The default
            value for this option is "vga" if nothing is provided explicitely.
            In order to pass the videoResolution, you need to make it available
            in the url as mentioned below.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&videoResolution=<vga>`}
          </p>
        </div>
        <div className="point-div">
          <h3>4. produce</h3>
          <p className="para-1">
            This parameter id useful if you wish to join in a listen only mode.
            In this case, you will not be able to share your camera/mic/screen
            but join the room in a view only mode where you can view and listen
            to all others but others can't view / listen you. The default vaule
            for this is true.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&produce=<false>`}
          </p>
        </div>
        <div className="point-div">
          <h3>5. produceAudio / produceVideo</h3>
          <p className="para-1">
            This parameter id useful if you wish to join the room by selectively
            choosing to not share your mic / camera. In this case, you will not
            be able to share your mic / camera based on the choice. The default
            vaules for both the options are true. Please keep in mind that these
            options are useful only if the produce option is set to true. If the
            produce option is set to false, then thse settings have no impact.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&produceAudio=<false>`}
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&produceVideo=<false>`}
          </p>
        </div>
        <div className="point-div">
          <h3>6. consume</h3>
          <p className="para-1">
            This parameter id useful if you wish to join a room in the
            broadcasting/ one way live streaming mode where you are sharing your
            mic / camera / screen but not viewing / listening to any other user
            in a room. this settign is useful when a teacher is teaching to
            students in an one way streaming mode. The default value of this
            setting is true.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&consume=<false>`}
          </p>
        </div>
        <div className="point-div">
          <h3>7. forcePCMU</h3>
          <p className="para-1">
            This parameter forces the use of the PCMU audio codec.The default
            audio codec used in OPUS. PCMU is particulary useful in environments
            with varying network conditions, as it ensures stable audio quality
            and low latency. The default value of this setting is false.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&forcePCMU=<false>`}
          </p>
        </div>
        <div className="point-div">
          <h3>8. forceH264</h3>
          <p className="para-1">
            This parameter forces the use of H264 video codec for efficient
            compression and high-quality video streaming. The default video
            codec is VP8. This codec works well across different devices and
            network conditions. The default value of this setting is false.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&forceH264=<false>`}
          </p>
        </div>
        <div className="point-div">
          <h3>9. h264Profile</h3>
          <p className="para-1">
            This parameter specifies the H264 profile for video encoding, which
            decides the video compression quality. Common profiles were low and
            high. The default value of this setting is high.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&h264Profile=<high>`}
          </p>
        </div>
        <div className="point-div">
          <h3>10. forceFPS</h3>
          <p className="para-1">
            This parameter sets the video frame rate, which directly affects the
            smoothness of the video. A higher frame rate requires more
            processing power but provides smoother motion, while low frame rate
            uses less resources results in a less smoother video. The default
            value of this setting is 25.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&forceFPS=<25>`}
          </p>
        </div>
        <div className="point-div">
          <h3>11. enableWebcamLayers</h3>
          <p className="para-1">
            This parameter enables simulcast layers for the webcam, which allows
            different resolutions to be sent simultaneously. This feature
            ensures that participants with varying network conditions can
            receive the most appropriate video quality. The default value of
            this setting is true.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&enableWebcamLayers=<true>`}
          </p>
        </div>
        <div className="point-div">
          <h3>12. numSimulcastStreams</h3>
          <p className="para-1">
            This parameter specifies the number of simulcast streams to produce,
            The default value of this setting is 3.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&numSimulcastStreams=<3>`}
          </p>
        </div>

        <div className="point-div">
          <h3>13. videoBitRates</h3>
          <p className="para-1">
            This parameter control the bit rates for different simulcast layers,
            affecting the video quality and bandwith usage. You can adjust the
            bit rates to change the video quality for different layers. By
            default this setting will take 500, 200, 75 for high, medium and low
            bitrates respectively.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&videoBitRateHigh=<300>&videoBitRateMedium=<150>&videoBitRateLow=<75>
`}
          </p>
        </div>
        <div className="point-div">
          <h3>14. autoGainControl</h3>
          <p className="para-1">
            This parameter enables automatic gain control for the
            microphone.This helps maintain consistent audio level by adjusting
            the microphone sensitivity in response to changes in speaking
            volume. This will be veryuseful when you move closer or further from
            the microphone The default value of this setting is true.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&autoGainControl=<true>`}
          </p>
        </div>
        <div className="point-div">
          <h3>15. echoCancellation</h3>
          <p className="para-1">
            This parameter when enabled prevents the feedback loop by detecting
            and eliminating the echo from the audio signal. It ensures that when
            someone speaks, their voice is not picked up by their own microphone
            and sent back to them or others in the call. By default this setting
            is true.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&echoCancellation=<true>`}
          </p>
        </div>
        <div className="point-div">
          <h3>16. noiseSuppression</h3>
          <p className="para-1">
            This parameter when enabled reduces background noise, ensuring that
            your voice remains clear even in noisy environments. By default this
            setting is true.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&noiseSuppression=<true>`}
          </p>
        </div>
        <div className="point-div">
          <h3>17. sampleRate</h3>
          <p className="para-1">
            This parameter specifies the audio sample rate, which determines the
            quality of the audio stream. Higher sample rates(1600Hz) result in
            better audio but requires more processing power, while lower sample
            rates are efficient but result in lower audio quality.For PCMU, this
            setting should be set to 8000. Otherwise, default setting for this
            parameter is 44000.
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&sampleRate=<44000>`}
          </p>
        </div>
        <div className="point-div">
          <h3>18. channelCount</h3>
          <p className="para-1">
            This parameter refers to the number of audio channels that are
            captured and transmitted in an audio stream. It determines whether
            the audio is mono (a single audio channel with the same signal sent
            to all speakers) or stereo (two audio channels, with one for the
            left speaker and one for the right). The OPUS codec supports both
            mono and stereo signals. Depending on the device's capabilities and
            CPU performance, the channel count can be increased to 2. The
            default setting for this parameter is 1 (mono).
          </p>
          <p className="link-block">
            {`https://demo.vidscale.live/?roomId=<abc123>&channelCount=<1>`}
          </p>
        </div>
      </section>
    </section>
  );
};

export default HelpPage;
