import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./joinRoom.modules.css";

const JoinRoom = () => {
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [isMicOn, setIsMicOn] = useState(false);
  const [devices, setDevices] = useState([]);
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const accessUserMedia = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        setIsVideoOn(true);
        setIsMicOn(true);
      })
      .catch((error) => {
        console.error("Error accessing user media:", error);
      });
  };

  const enumerateDevices = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => setDevices(devices));
  };
  useEffect(() => {
    accessUserMedia();
    enumerateDevices();
  }, []);

  console.log(devices);

  const handleJoinButtonClick = () => {
    navigate("/meeting-room");
  };
  return (
    <div className="join-room">
      <div className="joinee-video">
        <video
          className="joinee-video-element"
          ref={videoRef}
          autoPlay
          playsInline
          muted
        />
      </div>
      <div className="joinee-user-media">
        <div className="joinee-media-video">
          Video: {isVideoOn ? "On" : "Off"}
        </div>
        <div className="joinee-media-mic">Audio: {isMicOn ? "On" : "Off"}</div>
      </div>
      <button onClick={handleJoinButtonClick} className="join-button">
        Join Room
      </button>
    </div>
  );
};

export default JoinRoom;
