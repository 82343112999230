import React, { useEffect, useRef } from "react";
import { withRoomContext } from "../../lib/RoomContext";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

class PeerVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.videoRef = React.createRef();
    this.audioRef = React.createRef();

    this.audioTrack = null;
    this.videoTrack = null;
    // const navigate = useNavigate();
  }
  componentDidMount() {
    const { audioTrack, videoTrack } = this.props.peers[this.props.peerId];

    this._setTracks(audioTrack, videoTrack);
  }

  componentDidUpdate() {
    const { audioTrack, videoTrack } = this.props.peers[this.props.peerId];

    this._setTracks(audioTrack, videoTrack);
  }

  _setTracks(audioTrack, videoTrack) {
    if (this._audioTrack === audioTrack && this._videoTrack === videoTrack)
      return;

   
   

    // const { audioElem, videoElem } = this.refs;

    if(this.audioRef.current){
      if (audioTrack) {
        const stream = new MediaStream();
        this._audioTrack = audioTrack;
        stream.addTrack(audioTrack);
        this.audioRef.current.srcObject = stream;
  
        this.audioRef.current
          .play()
          .catch((error) => console.warn("audioElem.play() failed:%o", error));
  
        // this._runHark(stream);
      } else {
        this.audioRef.current.srcObject = null;
      }
    }
    
if(this.videoRef.current){
  if (videoTrack) {
    const stream = new MediaStream();

    stream.addTrack(videoTrack);
    this.videoRef.current.srcObject = stream;
    this._videoTrack = videoTrack;
    console.log("New video track for peer", this.props.peerId);
    this.videoRef.current.onplay = () => {
      // this.setState({ videoElemPaused: false });

      this.videoRef.current
        .play()
        .catch((error) => console.warn("audioElem.play() failed:%o", error));
    };

    // this.videoRef.current
    //   .play()
    //   .catch((error) => console.warn("videoElem.play() failed:%o", error));
  } else {
    this.videoRef.current.srcObject = null;
  }
}
   
  }
  render() {
    const { peerId,roomClient,peers } = this.props;
    console.log("Peer object is", peers[peerId]);
    return (
      <div>
        <div>
          
        {peers[peerId].video?
        <video
          ref={this.videoRef}
          autoPlay
          playsInline
          muted
          controls={false}
        />:<span style={{width:"320px",height:"240px",border:"1px solid red"}}>Video Off!</span>}
        <audio
          ref={this.audioRef}
          autoPlay
          muted={!peers[peerId].audioTrack}
          controls={false}
        />
        </div>
        <div>
        {peers[peerId].peerName}
        {peers[peerId].muted?
          <span style={{color:"red"}}>Mic muted!</span>:null}
        </div>
        {peers[peerId].me?
        <div>
          {peers[peerId].muted?
        <button onClick={()=>roomClient.unMute()}>Un-Mute</button>:<button onClick={()=>roomClient.mute()}>Mute</button>}
        {peers[peerId].video?
        <button onClick={()=>roomClient.cameraOff()}>Camera Off</button>:<button onClick={()=>roomClient.cameraOn()}>Camera On</button>}
        </div>:null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  peers: state.room.peers,
});
export default withRoomContext(connect(mapStateToProps,null)(PeerVideo));
