// import { useState } from "react";
// import {useEffect} from 'react';
import "./App.css";

// import vidScale from './lib/vidScale';
import JoinRoom from "./components/joinRoom/joinRoom";
import MeetingRoom from "./components/meetingRoom/meetingRoom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ThankYou from "./components/meetingRoom/thankYou";
import HelpPage from "./components/help/helpPage";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<MeetingRoom />}></Route>
          {/* <Route exact path="/meeting-room" element={<MeetingRoom />}></Route> */}
          <Route exact path="/thanks" element={<ThankYou />}></Route>
          <Route exact path="/help" element={<HelpPage />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
