import React from "react";

const ThankYou =()=>{

    return(
        <div>
            <h1>Thank you for using our services.</h1>
        </div>
    )

}

export default ThankYou;